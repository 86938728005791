import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import pic1 from "../../assets/pic1.jpg";
import pic2 from "../../assets/pic2.jpg";
import pic3 from "../../assets/pic3.jpg";
import pic4 from "../../assets/pic4.jpg";

function CenterMode() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 4,
    speed: 500,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <div className="newz">
            <div class="card">
              <div class="card-header">
                <img src={pic1} alt="rover" />
              </div>
              <div class="card-body">
                <h4>कान्तिपुर सवारी सेवा चाँडै सुरु हुने भएको छ ।</h4>
                <p>चाँडै सेवाहरूको आनन्द लिन तयार हुनुहोस्।</p>
                <div class="user">
                  <div class="user-info">
                    <small>2h ago</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="newz">
            <div class="card">
              <div class="card-header">
                <img src={pic2} alt="rover" />
              </div>
              <div class="card-body">
                <h4>
                  सबै कान्तिपुर सवारी सेवाहरू तपाईको हातमा छिटो र निरन्तर।
                </h4>
                <p>सेवाहरूको आनन्द लिन तयार हुनुहोस्।</p>
                <div class="user">
                  <div class="user-info">
                    <small>15 min ago</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="newz">
            <div class="card">
              <div class="card-header">
                <img src={pic3} alt="rover" />
              </div>
              <div class="card-body">
                <h4>कान्तिपुर सवारीमा महिलालाई प्राथमिकता</h4>
                <p>ड्राइभिङका लागि महिला र यात्रु दुवैलाई प्राथमिकता दिइन्छ</p>
                <div class="user">
                  <div class="user-info">
                    <small>5h ago</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="newz">
            <div class="card">
              <div class="card-header">
                <img src={pic4} alt="rover" />
              </div>
              <div class="card-body">
                <h4>अब देखि कार भाडा सेवा पनि सुरु हुनेछ</h4>
                <p>ड्राइभिङका लागि महिला र यात्रु दुवैलाई प्राथमिकता दिइन्छ</p>
                <div class="user">
                  <div class="user-info">
                    <small>1 day ago</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default CenterMode;

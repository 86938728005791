import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import student from "../../assets/card/student.png";
import book from "../../assets/card/book.png";
import telephone from "../../assets/card/telephone.png";
import clock from "../../assets/card/future.png";
import payment from "../../assets/card/payment.png";
import gift from "../../assets/card/gift.png";
import location from "../../assets/card/location.png";
import review from "../../assets/card/documents.png";
import travel from "../../assets/card/travel.png";
import rateestimator from "../../assets/card/rateestimator.png";
import notification from "../../assets/card/notification.png";
import notifications from "../../assets/card/notifications.png";
import map from "../../assets/card/map.png";
import key from "../../assets/card/key.png";
import folder from "../../assets/card/folder.png";
import search from "../../assets/card/search.png";
import analysis from "../../assets/card/analysis.png";
function Responsive() {
  var settings = {
    dots: false,
    arrow: true,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: false,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>PROFILE</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={student} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  A user/service provider can update their detailed profile and
                  also add/remove their info from the application. It is ensured
                  that information is not stored without the approval or
                  knowledge of a user.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>SAVED ADDRESS</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={book} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  Frequently visited addresses can be saved. This eliminates the
                  repetitive entry of a location every time a user travels. For
                  example, a user can add and then automatically choose their
                  home address instead of typing it every time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>SCHEDULE BOOKINGS</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={telephone} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  If one needs to commute daily at a routine time, this feature
                  enables them to perform schedule bookings.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>BOOKING RECORDS</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={clock} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  This feature keeps a record of the user’s trip destinations,
                  the price of trips and other information. It helps the user in
                  case he/she needs to obtain information on their past trips.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>MULTIPLE PAYMENT METHODS</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={payment} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  This feature enables the user to pay through multiple modes
                  like cash, card or any e-commerce payment applications,
                  therefore, making it convenient for users to make payments.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>RATE CARD</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={gift} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  When the user is not sure of proper destination, the rate card
                  gives a rate in accordance with the distance travelled.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>LIVE VEHICLE TRACKING</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={location} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  Real-time tracking helps the customer to be aware of the
                  location of their delivery and also for drivers and riders to
                  locate each other.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>REVIEWING</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={review} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  This feature helps with maintaining quality standards on the
                  application based on feedback received from both service
                  providers and customers.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>ESTIMATED TRAVEL TIME</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={travel} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  This feature enables one to calculate the estimated travel
                  time from the pick-up point to the destination point.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>TRIP RATE ESTIMATOR</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={rateestimator} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  Trip Fare estimator helps to calculate the approximate trip
                  fare based on the distance to be travelled.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>SMS/ EMAIL NOTIFICATIONS</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={notification} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  This feature enables the service provider to send
                  notifications or alerts to their customers through SMS or
                  Emails.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>INSTANT NOTIFICATION</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={notifications} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  The app instantly alerts or notifies its users on booking
                  status, change/cancellation, payment notifications, etc.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>CALL FEATURES</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={telephone} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  Users of the application can communicate with the inbuilt call
                  service provided for them.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>PROMO CODE</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={gift} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  This feature enables the users of the app to enjoy discounts
                  and offers, thereby bringing in more users.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>GEO-FENCING</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={map} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  Geo-fencing allows the drivers/delivery person to locate the
                  customers and vice versa. Its accuracy goes a long way in
                  increasing the efficiency of the service provided.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>AUTHENTICATION</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={key} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  This feature helps with maintaining quality standards on the
                  application based on feedback received from both service
                  providers and customers.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>AVAILABILITY OPTIONS</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={folder} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  Availability toggles allow users to determine whether
                  drivers/delivery personnel are available to provide their
                  services.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>FLEXIBLE VEHICLE OPTION</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={search} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  An app provides its users with multiple vehicle options to
                  choose from based on their requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="feature">
            <div className="card">
              <div className="chead">
                <h5>ANALYTICS TRACKING</h5>
              </div>
              <div className="cpic">
                <div className="animation"></div>
                <Link to={"/"} className=" logo">
                  <img src={analysis} />
                </Link>
              </div>
              <div className="cdesc">
                <p>
                  This feature helps collect user-based data to make changes and
                  further improve the services provided.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default Responsive;

import React from "react";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Responsive from "./components/Slider";
import BannerImage from "./assets/banner.png";
import Image from "./assets/qrcode.png";
import Images from "./assets/rideb.jpg";
import Phones from "./assets/phone.png";
import arrows from "./assets/arrow.png";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FaTaxi } from "react-icons/fa6";
import { FaMotorcycle } from "react-icons/fa6";
import { PiPackageBold } from "react-icons/pi";
import { PiChefHatBold } from "react-icons/pi";
import { BsBagFill } from "react-icons/bs";
import { PiFlowerTulip } from "react-icons/pi";
import { MdOutlineElectricalServices } from "react-icons/md";
import { FaUserDoctor } from "react-icons/fa6";
import { FaFaucetDrip } from "react-icons/fa6";
import { PiHairDryerFill } from "react-icons/pi";
import { FaPaw } from "react-icons/fa";
import { MdLocalLaundryService } from "react-icons/md";
import { GiVacuumCleaner } from "react-icons/gi";
import { FaToolbox } from "react-icons/fa";
import { GiGrass } from "react-icons/gi";
import { RiGroup3Line } from "react-icons/ri";
import { MdMenuBook } from "react-icons/md";
import { TbMassage } from "react-icons/tb";
import { FaCompactDisc } from "react-icons/fa6";
import { FaBabyCarriage } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "./assets/kantipur_logo-removebg-preview.png";
import StarIcon from "@mui/icons-material/Star";
import CenterMode from "./components/News";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <div className="main">
        <div className="banner">
          <img src={BannerImage} className="img-fluid" alt="Banner" />
          <div className="texts">
            <div className="btext">
              <span>K</span>
              <span>A</span>
              <span>N</span>
              <span>T</span>
              <span>I</span>
              <span>P</span>
              <span>U</span>
              <span>R</span>
            </div>
            <div className="btexts ms-2">
              <span>R</span>
              <span>I</span>
              <span>D</span>
              <span className="hhh">E</span>
            </div>
            <p className="ptext">
              Encompass a wide variety of on-demand services from taxi rides and
              delivery services to babysitting and laundry services in a single
              Kantipur Ride app.
            </p>
            <button
              type="button"
              className="btn btn-danger btn-rounded"
              data-mdb-ripple-init
            >
              Go to App
            </button>
          </div>
        </div>

        <section id="services">
          <h1>
            <span className="hh1">ONE APP</span>
            <span className="hh2"> FOR ALL YOUR NEEDS</span>
          </h1>
          <p className="paragraph">
            We are now developing an app like Kantipur Ride for entrepreneurs
            across the world. Kantipur Ride app is an amalgam of a variety of
            on-demand services onto a single platform. Kantipur Ride whitelabel
            solutions are highly customizable and integrated with a myriad of
            features to choose from. Our ready-made solutions help your startup
            to innovate and grow faster than your counterparts.
          </p>
          <div className="stab mt-5">
            <CenteredTabs />
          </div>
        </section>

        <div className="ride">
          <div className="row">
            <div className="col-lg-6 ride1">
              <h1>
                <span className="subh1">Making millions of rides</span>
                <span className="subh2"> more accessible</span>
              </h1>
              <p className="mt-3">
                A ride is more than just a ride. It's a gateway to opportunities
                and jobs. A connection to community. And access to essentials
                like groceries, healthcare, and polling places. Our Kantipur
                Ride initiative makes rides more accessible for millions, and
                helps bring communities even closer.
              </p>
              <button
                type="button"
                className="btn btn-danger btn-rounded mt-1"
                data-mdb-ripple-init
              >
                Learn More
              </button>
            </div>
            <div className="col-lg-6 ride2">
              <img src={Images} className="img-fluid" alt="ride" />
            </div>
          </div>
        </div>

        <section id="features">
          <h1 className="fhead">
            <span className="ff1">KANTIPUR RIDE APP </span>
            <span className="ff2">FEATURES</span>
          </h1>

          <p className=" fdesc mt-3">
            Kantipur Ride depending on what service is required by the user, has
            three options. The workflow of these respective choices are as
            follows:
          </p>
          <div className="container-fluid">
            <div className="slider">
              <Responsive />
            </div>
          </div>
        </section>

        <div className="App">
          <div className="row">
            <div className="col-lg-6 phone2">
              <div className="phone">
                <img src={Phones} className="img-fluid" alt="ride" />
              </div>
              <div className="arrow">
                <img src={arrows} className="img-fluid" alt="ride" />
              </div>
            </div>
            <div className="col-lg-6 phone1">
              <div className="phead">
                <Link to={"/"} className=" logo">
                  <img src={logo} />
                </Link>

                <h5>
                  KANTIPUR RIDE AP<span className="phon">P</span>{" "}
                </h5>
              </div>
              <div className="star d-flex">
                <StarIcon /> <StarIcon /> <StarIcon /> <StarIcon /> <StarIcon />
              </div>

              <div className="mobile">
                <h1>Mobile App</h1>
                <p>
                  Download Kantipur Mobile App – one app for rides, consignment
                  , and dispatch.
                </p>

                <button
                  type="button"
                  className="btn btn-danger btn-rounded mt-5"
                  data-mdb-ripple-init
                >
                  Download the App
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="news">
          <h1 className="nhead">
            NEWSLETTE<span className="nred">R</span>{" "}
          </h1>
          <p>
            Kantipur ride news on and off the service and latest news for all.
          </p>

          <CenterMode />
        </div>

        <div className="qrcode">
          <h1 className="qrhead">
            <span className="qr1">It's easier in the </span>
            <span className="qr2">apps</span>
          </h1>
          <div className="row">
            <div className="col-lg-6 mt-4 ">
              <div className="card d-flex">
                <img src={Image} className="img-fluid" alt="qrcode" />
                <div className="ctext">
                  <h2>Download Kantipur app</h2>
                  <p>Scan to download</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              <div className="card d-flex">
                <img src={Image} className="img-fluid" alt="qrcode" />
                <div className="ctext">
                  <h2>Download Kantipur app</h2>
                  <p>Scan to download</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function CenteredTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: "background.paper", width: 500, margin: "0 auto" }}>
      <AppBar position="static">
        <Tabs
          className="tabb mt-2"
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Rides" {...a11yProps(0)} />
          <Tab label="Deliveries" {...a11yProps(1)} />
          <Tab label="Services" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div className="row">
            <div className="col-lg-4 card1">
              <div className="card">
                <div className="d1">
                  <span className="icon">
                    <FaTaxi />
                  </span>
                </div>
                <div className="d2">
                  <p>Taxi Ride</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 card1">
              <div className="card bg-success">
                <div className="d1">
                  <span className="icon">
                    <FaMotorcycle />
                  </span>
                </div>
                <div className="d2">
                  <p>Moto Rental</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 card1">
              <div className="card bg-info">
                <div className="d1">
                  <span className="icon">
                    <PiPackageBold />
                  </span>
                </div>
                <div className="d2">
                  <p>Movers</p>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div className="row">
            <div className="col-lg-4 card1">
              <div className="card">
                <div className="d1">
                  <span className="icon">
                    <PiChefHatBold />
                  </span>
                </div>
                <div className="d2">
                  <p>Food</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 card1">
              <div className="card bg-success">
                <div className="d1">
                  <span className="icon">
                    <BsBagFill />
                  </span>
                </div>
                <div className="d2">
                  <p>Grocery</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 card1">
              <div className="card bg-info">
                <div className="d1">
                  <span className="icon">
                    <PiFlowerTulip />
                  </span>
                </div>
                <div className="d2">
                  <p>Flower</p>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <div className="row">
            <div className="col-lg-4 card1">
              <div className="card">
                <div className="d1">
                  <span className="icon">
                    <MdOutlineElectricalServices />
                  </span>
                </div>
                <div className="d2">
                  <p>Electrician</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 card1">
              <div className="card bg-success">
                <div className="d1">
                  <span className="icon">
                    <FaUserDoctor />
                  </span>
                </div>
                <div className="d2">
                  <p>Doctor</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 card1">
              <div className="card bg-info">
                <div className="d1">
                  <span className="icon">
                    <FaFaucetDrip />
                  </span>
                </div>
                <div className="d2">
                  <p>Plumber</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-4 card1">
              <div className="card bg-danger">
                <div className="d1">
                  <span className="icon">
                    <PiHairDryerFill />
                  </span>
                </div>
                <div className="d2">
                  <p>Beauty Services</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 card1">
              <div className="card bg-secondary">
                <div className="d1">
                  <span className="icon">
                    <FaPaw />
                  </span>
                </div>
                <div className="d2">
                  <p>Dog Walking</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 card1">
              <div className="card bg-warning">
                <div className="d1">
                  <span className="icon">
                    <MdLocalLaundryService />
                  </span>
                </div>
                <div className="d2">
                  <p>Laundry</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-4 card1">
              <div className="card bg-info">
                <div className="d1">
                  <span className="icon">
                    <GiVacuumCleaner />
                  </span>
                </div>
                <div className="d2">
                  <p>House Cleaning</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 card1">
              <div className="card bg-primary">
                <div className="d1">
                  <span className="icon">
                    <FaToolbox />
                  </span>
                </div>
                <div className="d2">
                  <p>Carpenter</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 card1">
              <div className="card bg-muted">
                <div className="d1">
                  <span className="icon">
                    <GiGrass />
                  </span>
                </div>
                <div className="d2">
                  <p>Lawn Mower</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-4 card1">
              <div className="card bg-danger">
                <div className="d1">
                  <span className="icon">
                    <RiGroup3Line />
                  </span>
                </div>
                <div className="d2">
                  <p>Cuddling</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 card1">
              <div className="card bg-success">
                <div className="d1">
                  <span className="icon">
                    <MdMenuBook />
                  </span>
                </div>
                <div className="d2">
                  <p>Tutor</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 card1">
              <div className="card bg-info">
                <div className="d1">
                  <span className="icon">
                    <TbMassage />
                  </span>
                </div>
                <div className="d2">
                  <p>Massage</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-4 card1">
              <div className="card bg-warning">
                <div className="d1">
                  <span className="icon">
                    <FaCompactDisc />
                  </span>
                </div>
                <div className="d2">
                  <p>DJ</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 card1">
              <div className="card bg-secondary">
                <div className="d1">
                  <span className="icon">
                    <FaBabyCarriage />
                  </span>
                </div>
                <div className="d2">
                  <p>Baby Sitting</p>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}

export default App;
